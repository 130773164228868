import React from 'react';
import About from '../components/About'
import Companies from '../components/Companies'
import Hero from '../components/Hero';
import Gallery from '../components/Gallery';

function Home() {
  return (
    <main className="scroll-smooth">
      <section id="hero">
        <Hero />
      </section>

      <section id="about">
        <About />
      </section>

      <section id="companies">
        <Companies />
      </section>

      <section id="gallery">
        <Gallery />
      </section>
    </main>
  );
}

export default Home;
