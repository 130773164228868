import React, {useEffect} from "react";
import ADT from '../assets/ADT.png'
import SBA from '../assets/SBA.png'
import AOS from 'aos';
import 'aos/dist/aos.css';

const Companies = () => {
    useEffect(() => {
        AOS.init({
          duration: 1000, // Animation duration
          easing: 'ease-out', 
          once: true, 
        });
      }, []);

      return (
        <div className="flex flex-col items-center py-12 px-8 pb-40" data-aos="fade-up">
          <div className="mb-12 text-center">
            <h2 className="text-4xl font-bold text-white">I've Had The Pleasure Of Working With</h2>
          </div>

          <div className="flex justify-center items-center shadow-lg rounded-lg  border border-grey">
            <div className="flex justify-center items-center space-x-6 pr-8">
              <img src={ADT} alt="Company 1" className="w-40 h-40 object-contain transition-transform duration-300 hover:scale-105" />
              <img src={SBA} alt="Company 2" className="w-40 h-40 object-contain transition-transform duration-300 hover:scale-105" />
            </div>
          </div>

        </div>
      );
}

export default Companies;