import React from "react";
import { motion } from "framer-motion";
import minimalist from '../assets/package-lock.gif'

const Hero = () => {
    return (
        <motion.div 
      initial={{ opacity: 0, y: 20 }} 
      animate={{ opacity: 1, y: 0 }} 
      transition={{ duration: 1, ease: "easeOut" }} 
      className="relative bg-cover bg-center h-screen flex items-center justify-center text-center text-white "
    >
      <section className="flex flex-col justify-center items-center h-screen">
      <h1 className="text-5xl font-bold mb-4">Kaleb Ellington</h1>
      <p className="text-xl text-gray-400 ">Computer Science Student</p>
      </section>
        </motion.div>
    );
}

export default Hero; 
