import React, {useState, useRef} from 'react';

const EducationCard = ({ major, university, duration}) => {
    const contentRef = useRef(null);
  return (
    <div className="relative mb-4 pl-4">
      <div className="bg-black border border-white text-white shadow-md rounded-lg p-4 flex items-center justify-between cursor-pointer">
        <div className="flex flex-col">
          <h3 className="text-lg font-bold">{major} @ {university}</h3>
        </div>

        <div className="flex items-center space-x-4">
          <p className="text-sm text-white">{duration}</p>
        </div>
      </div>
    </div>
  );
};

export default EducationCard;