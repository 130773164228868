import React, {useEffect} from "react";
import portfolioImage from '../assets/portfolio_pic.png'
import AOS from 'aos';
import 'aos/dist/aos.css';

const About = () => {
    useEffect(() => {
        AOS.init({
          duration: 1750, // Animation duration
          easing: 'ease-out', 
          once: true, 
        });
      }, []);

    return (
        <div className="flex justify-between items-center pl-12 pb-32 pt-0 px-6 " data-aos="fade-up">
            <div className="w-7/12 pr-8 text-white"> 
                <h2 className="text-3xl font-bold mb-4">About Me</h2>
                <p className="text-lg leading-relaxed">
                    Hi, I'm Kaleb, a passionate developer with a knack for creating intuitive and dynamic user experiences. 
                    I specialize in web development and have a deep understanding of modern front-end technologies. My journey started 
                    eager to learn new things and take on challenging projects that allow me to grow my skillset.

                </p>
            </div>
  
            <div className="w-5/12 flex justify-center items-center"> 
                <img src={portfolioImage} alt="Portfolio" className="rounded-full h-72 w-72 object-cover" />
            </div>
         </div>
  );
}

export default About;