import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import ExperienceCard from '../components/ExperienceCard';

const ExperienceTimeline = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const experiences = [
    {
      company: 'SBA Communications',
      role: 'Software Engineer Intern',
      duration: 'Aug 2024 - Present',
      details: 'As a Software Engineer, I am currently developing an Android application using Kotlin that leverages the Google Maps SDK to display various data points on a Google map. These data points are fetched from the public ArcGIS API, which I integrate into the app through a RESTful API backend built with Flask. This backend service ensures efficient data retrieval and handling for our applications mapping features. \n\n In addition to this, I am building an AI-driven content generation model specifically designed to create real estate lease termination letters. For this project, I utilize Python, leveraging the pandas library for data manipulation and front-end frameworks to deliver a seamless and user-friendly experience. This AI application automates the process of generating personalized, legally sound documents, enhancing productivity and reducing manual effort.',
      technologies: ['Kotlin', 'Java', 'Google Maps SDK', 'Python', 'Pandas', 'Flask', 'AWS']
    },
    {
      company: 'ADT',
      role: 'Site Reliability Engineer Intern',
      duration: 'May 2024 - Aug 2024',
      details: 'In my role as a Site Reliability Engineer (SRE), I played a key part in migrating an outdated IBM LDAP instance to a modern OpenLDAP instance within the company’s Google Kubernetes Engine (GKE) environment, leveraging Docker containers to ensure a smooth transition and improve performance and scalability. I utilized Python and Bash scripting extensively to automate various processes, which streamlined operations and reduced manual effort. Additionally, I developed a proof of concept to modernize the electronic Capital Expenditure Request (eCer) system by integrating a state-of-the-art platform within ServiceNow, enhancing the systems scalability, usability, and efficiency. I actively monitored applications and services using Dynatrace to detect potential service disruptions, enabling proactive measures to prevent outages and maintain service reliability. During this time, I also expanded my knowledge of cloud technologies, gaining experience with AWS, Azure, and Google Cloud.',
      technologies: ['Python', 'Docker', 'Kubernetes', 'Dynatrace', 'Bash', 'Azure', 'AWS', 'GCP', 'Ansible']
    }
  ];

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  useEffect(() => {
    AOS.init({
      duration: 1750, // Animation duration
      easing: 'ease-out', 
      once: true, 
    });
  }, []);

  return (
    <div className="py-12 mb-10 px-8" data-aos="fade-up">
      <h2 className="text-3xl font-bold text-center mb-8 text-white">Professional Experience</h2>
      <div className="relative">
        {experiences.map((experience, index) => (
          <ExperienceCard
            key={index}
            {...experience}
            isExpanded={expandedIndex === index}
            onToggle={() => handleToggle(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default ExperienceTimeline;
