import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import axios from 'axios';

const Contact = () => {
  const formRef = useRef();

  const onSubmit = async (e) => {
    e.preventDefault();

    // Ensure reCAPTCHA is ready and execute it
    if (!window.grecaptcha) {
      alert('reCAPTCHA is not loaded. Please try again later.');
      return;
    }

    // Execute reCAPTCHA Enterprise to get a token
    window.grecaptcha.enterprise.ready(async () => {
      try {

        const token = await window.grecaptcha.enterprise.execute(
          '6LcU7TMqAAAAAOifLPCs6j8G-MwEh1fjmShGfr4f', 
          { action: 'submit' }
        );

        // Send the token to the backend for validation
        const response = await axios.post('/api/reqrec', {
          "token": token,
          "userAction": 'submit',
        });

        console.log(response.data);

        if (response.data.success) {
          // If reCAPTCHA validation succeeds, proceed with sending the email
          sendEmail(e);
        } else {
          alert('reCAPTCHA validation failed.');
        }
      } catch (error) {
        console.error('Error during token verification:', error);
        alert('reCAPTCHA validation failed.');
      }
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      'service_gvvag48', // Your service ID
      'template_l63vv98', // Your template ID
      formRef.current, // Form reference
      'Y8-WOcGgdGOHrWLSn' // Your public user ID (public key)
    )
      .then(
        (result) => {
          console.log(result.text);
          alert('Message sent successfully!');
        },
        (error) => {
          console.log(error.text);
          alert('An error occurred, please try again.');
        }
      );

    e.target.reset(); // Reset form after submission
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-black text-white p-4">
      <h1 className="text-4xl font-bold mb-6">Contact Me</h1>

      <form ref={formRef} onSubmit={onSubmit} className="w-full max-w-lg">
        <div className="mb-4">
          <label className="block text-white text-sm font-bold mb-2" htmlFor="name">
            Name
          </label>
          <input
            className="w-full px-3 py-2 border border-white rounded-lg bg-black text-white focus:outline-none focus:ring-2 focus:ring-white"
            type="text"
            id="name"
            name="from_name"
            placeholder="Your Name"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-white text-sm font-bold mb-2" htmlFor="email">
            Email
          </label>
          <input
            className="w-full px-3 py-2 border border-white rounded-lg bg-black text-white focus:outline-none focus:ring-2 focus:ring-white"
            type="email"
            id="email"
            name="from_email"
            placeholder="Your Email"
            required
          />
        </div>

        <div className="mb-6">
          <label className="block text-white text-sm font-bold mb-2" htmlFor="message">
            Message
          </label>
          <textarea
            className="w-full px-3 py-2 border border-white rounded-lg bg-black text-white focus:outline-none focus:ring-2 focus:ring-white"
            id="message"
            name="message"
            rows="5"
            placeholder="Your Message"
            required
          ></textarea>
        </div>

        <div className="flex justify-center">
          <button
            className="px-6 py-2 border border-white rounded-lg bg-transparent text-white hover:bg-white hover:text-black transition duration-300"
            type="submit"
          >
            Send Message
          </button>
        </div>
      </form>
    </div>
  );
};

export default Contact;
