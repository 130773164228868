import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import EducationCard from '../components/EducationCard';

const EducationTimeline = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const experiences = [
    {
      major: 'BS in Computer Science',
      university: 'University of Florida',
      duration: 'May 2023 - Present',
    },
    {
      major: 'AA in Computer Science',
      university: 'Santa Fe College',
      duration: 'May 2022 - May 2023',
    }
  ];

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  useEffect(() => {
    AOS.init({
      duration: 1750, // Animation duration
      easing: 'ease-out', 
      once: true, 
    });
  }, []);

  return (
    <div className="py-12 px-8" data-aos="fade-up">
      <h2 className="text-3xl font-bold text-center mb-8 text-white">Education</h2>
      <div className="relative">
        {experiences.map((experience, index) => (
          <EducationCard
            key={index}
            {...experience}
            isExpanded={expandedIndex === index}
            onToggle={() => handleToggle(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default EducationTimeline;