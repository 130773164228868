import React, { useState, useEffect } from 'react';
import 'yet-another-react-lightbox/styles.css'; // Import lightbox styles if using
import Lightbox from 'yet-another-react-lightbox';
import AOS from 'aos';

const importAll = (r) => {
    return r.keys().map(r);
  };
  
  // Use require.context to import all images in the folder
  const images = importAll(require.context('../assets/Gallery', false, /\.(png|jpe?g|svg)$/));
  

const Gallery = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);


  const handleClick = (index) => {
    setSelectedIndex(index);
    setIsOpen(true);
  };

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      easing: 'ease-out', 
      once: true, 
    });
  }, []);

  return (
    <div className="py-12 px-8" data-aos="fade-up">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {images.map((image, index) => (
          <div
            key={index}
            className="relative overflow-hidden rounded-lg shadow-lg group cursor-pointer w-full h-full"
            onClick={() => handleClick(index)}
          >
            <img
              src={image}
              alt={`Gallery Image ${index + 1}`}
              className="w-full h-full object-covert transition-transform duration-300 group-hover:scale-105"
            />
          </div>
        ))}
      </div>

      {isOpen && (
        <Lightbox
          open={isOpen}
          close={() => setIsOpen(false)}
          slides={images.map((image) => ({ src: image.default }))}
          index={selectedIndex}
          onIndexChange={setSelectedIndex}
        />
      )}
    </div>
  );
};

export default Gallery;
