import React, { useEffect, useRef, useState } from 'react';
import AOS from 'aos';

const ProjectCard = ({ title, description, technologies, repoLink }) => {
    useEffect(() => {
        AOS.init({
          duration: 1750, // Animation duration
          easing: 'ease-out', 
          once: true, 
        });
      }, []);

  return (
    <a
      href={repoLink}
      target="_blank"
      rel="noopener noreferrer"
      className="bg-black border border-white text-white shadow-lg rounded-lg p-6 w-80 m-2 flex-shrink-0 transition-transform duration-300 hover:scale-105"
      style={{ textDecoration: 'none' }}
      data-aos="fade-up" 
    >
      <h3 className="text-xl font-bold mb-2">{title}</h3>
      <p className="text-base mb-2">{description}</p>
      <p className="text-sm text-white pt-3 mb-4">Technologies: {technologies}</p>
      
    </a>
  );
};

const ProjectsList = ({ projects }) => {
  const scrollRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    let scrollSpeed = 2; // Speed of scrolling
    let intervalId;

    const startScrolling = () => {
      intervalId = setInterval(() => {
        if (scrollContainer) {
          scrollContainer.scrollLeft += scrollSpeed;

          // Check if we've scrolled past the original list of projects
          if (scrollContainer.scrollLeft >= scrollContainer.scrollWidth / 2) {
            scrollContainer.scrollLeft = 0; // Reset scroll position smoothly
          }
        }
      }, 20); // Adjust the interval for smoother or faster scrolling
    };

    const stopScrolling = () => {
      clearInterval(intervalId);
    };

    if (!isHovered) {
      startScrolling();
    } else {
      stopScrolling();
    }

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [isHovered]);

  return (
    <div className="py-8">
      <h2 className="text-3xl font-bold text-center mb-6 text-white" data-aos="fade-up">Projects</h2>
      <div
        ref={scrollRef}
        className="overflow-x-auto flex space-x-4 px-4"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{ display: 'flex', flexWrap: 'nowrap' }} // Ensures the items stay in one line
      >
        {/* Original list of projects */}
        {projects.map((project, index) => (
          <ProjectCard key={`original-${index}`} {...project} />
        ))}
        {/* Duplicate list of projects */}
        {projects.map((project, index) => (
          <ProjectCard key={`duplicate-${index}`} {...project} />
        ))}
      </div>
    </div>
  );
};

export default ProjectsList;
