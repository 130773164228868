import React from 'react';
import ExperienceTimeline from '../components/ExperienceTimeline';
import ProjectsList from '../components/ProjectList';
import EducationTimeline from '../components/EducationTimeline';


function Portfolio() {
  const projects = [
    {
      title: 'Home NAS',
      description: 'Engineered a cost-effective, subscription-free Network Attached Storage (NAS) solution using a Raspberry Pi,optimizing home network data management and ensuring secure, scalable access to personal files',
      technologies: 'Raspberry Pi, UNIX, Docker, Kubernetes, Tailscale, OMV, Plex',
      repoLink: '',
    },
    {
      title: 'Map Tutorial',
      description: 'The application allows users to visualize geographical data on an interactive map, featuring functionalities such as adding markers, drawing shapes, and customizing map styles. It demonstrates the integration of mapping services and APIs to create a responsive and user-friendly interface for exploring spatial information',
      technologies: 'Kotlin, Google Maps SDK, Android Studio, ',
      repoLink: 'https://github.com/KalebE36/Map_Tutorial',
    },
    {
      title: 'Kickback',
      description: 'Developed a full-stack web application using with SvelteKit serving a REST API with Svelte as the frontend. Also, Users can create accounts, post events, and interact with eachother through direct messaging and ”Kicking”',
      technologies: 'Svelte, Sveltekit, Google Firebase, Git',
      repoLink: 'https://github.com/Xa-el/FootyFriends',
    },
    {
        title: 'Finance Reader',
        description: 'a Python script designed to analyze recurring payments from an uploaded CSV file containing bank statements. The script leverages the Pandas library for data parsing and analysis, using regular expressions to identify and calculate recurring transactions. The results are then summarized and exported to a new CSV file, providing a clear overview of financial outflows. This tool is ideal for personal finance management and helps users better understand their recurring expenses.',
        technologies: 'Python, Pandas, re',
        repoLink: 'https://github.com/KalebE36/Finance_Reader',
    }, 
    {
        title: 'GatorLeasing',
        description: 'Driving a team of 3 other students to develop a mobile application for iOS devices using ReactNative, delivering a seamless and responsive user experience. Intended to help users with housing issues such as subleasing, finding roommates, and managing rental agreements',
        technologies: 'ReactNative, Git',
        repoLink: '',
      },
  ];

  return (
    <div className="min-h-screen text-white py-12 px-6">
      <div className="container mx-auto">
        {/* Experience Section */}
        <EducationTimeline />
        <ExperienceTimeline />

        {/* Projects Section */}
        <ProjectsList projects={projects} />
      </div>
    </div>
  );
};

export default Portfolio;
