import React, { useState, useRef, useEffect } from 'react';

const ExperienceCard = ({ company, role, duration, details, technologies, isExpanded, onToggle }) => {
  const contentRef = useRef(null);
  const [showBorder, setShowBorder] = useState(isExpanded);

  useEffect(() => {
    if (isExpanded) {
      setShowBorder(true);
    } else {
      const timer = setTimeout(() => {
        setShowBorder(false);
      }, 445); // Match this with the duration of your transition (0.5s)

      return () => clearTimeout(timer); // Clear timeout if component unmounts or isExpanded changes
    }
  }, [isExpanded]);

  return (
    <div className="relative mb-4 pl-4">
      <div className="bg-black border border-white text-white shadow-md rounded-lg p-4 flex items-center justify-between cursor-pointer">
        <div className="flex flex-col">
          <h3 className="text-lg font-bold">{role} @ {company}</h3>
        </div>

        <div className="flex items-center space-x-4">
          <p className="text-sm text-white">{duration}</p>
          <button
            onClick={onToggle}
            className="text-white hover:text-black-800 transition duration-300"
          >
            {isExpanded ? '-' : '+'}
          </button>
        </div>
      </div>

      {/* Conditional rendering for expanded details with smooth transition */}
      <div
        ref={contentRef}
        style={{
          maxHeight: isExpanded ? `${contentRef.current.scrollHeight}px` : '0px',
          transition: 'max-height 0.5s ease-in-out',
          border: showBorder ? '1px solid white' : 'none', // Conditionally show border
        }}
        className="overflow-hidden mt-3 bg-black rounded-lg shadow-md"
      >
        <div className="p-4">
          <p className="text-sm text-sky-400 mb-4">{details}</p>

          {/* Technology Bubbles */}
          <div className="flex justify-center flex-wrap gap-2">
            {technologies.map((tech, index) => (
              <span
                key={index}
                className="inline-block bg-black text-sky-400 border-white border text-xs font-semibold px-2.5 py-1 rounded-full"
              >
                {tech}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExperienceCard;