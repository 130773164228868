import React from 'react';
import logo from '../assets/Logo.png'; 
import instagram from '../assets/instagram-icon.png'
import github from '../assets/github-icon.png'
import linkedin from '../assets/linkedIn-icon.png'
import resume from '../assets/resume_logo.png'
import resumePDF from '../assets/Resume.pdf'
import { NavLink } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  return (
    <header className="bg-black text-white p-4">
    <div className=" flex justify-start items-center">
        <img src={logo} alt="Logo" className="h-24 w-24" />
        <nav className='flex-grow flex justify-center pl-12 ml-12'>
        <ul className="flex space-x-11 text-2xl">
        <li>
              <NavLink to="/" className={({ isActive }) => (isActive ? 'hover-item active' : 'hover-item')}>
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/portfolio" className={({ isActive }) => (isActive ? 'hover-item active' : 'hover-item')}>
                Portfolio
              </NavLink>
            </li>
            <li>
              <NavLink to="/contact" className={({ isActive }) => (isActive ? 'hover-item active' : 'hover-item')} >
                Contact
              </NavLink>
            </li>
        </ul>
      </nav>
      <div className="flex space-x-4">
          <a href={resumePDF} target="_blank" rel="noopener noreferrer">
            <img src={resume} alt="Resume" className="h-9 w-9" />
          </a>
          <a href="https://www.instagram.com/kalebjellington/" target="_blank" rel="noopener noreferrer">
            <img src={instagram} alt="Instagram" className="h-10 w-auto" />
          </a>
          <a href="https://github.com/KalebE36" target="_blank" rel="noopener noreferrer">
            <img src={github} alt="Github" className="h-9 w-auto" />
          </a>
          <a href="https://www.linkedin.com/in/kaleb-ellington/" target="_blank" rel="noopener noreferrer">
            <img src={linkedin} alt="LinkedIn" className="h-11 w-auto" />
          </a>
        </div>
    </div>
  </header>
  );
};

export default Navbar;
